import React from "react"

import Layout from "../../components/layout"
import Photoshop from "../../components/screens/Skill/Photoshop"
import { Helmet } from "react-helmet"

const PtsPage = () => (
  <Layout pageInfo={{ pageName: "Photoshop/Illustrator" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>Photoshop Illustrator</title>
        <meta name="description" content="Photoshop Illustrator" />
    </Helmet>
    <Photoshop />
  </Layout>
)

export default PtsPage
